.intensive-timer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--mg-space-8) var(--mg-space-16);
  color: var(--mg-color-primary-50);
  background: var(--mg-color-light);
  background-size: cover;

  &__content {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    max-width: 55%;
    color: var(--mg-color-light);
    column-gap: var(--mg-space-24);

    @media (max-width: 575.98px) {
      flex-wrap: wrap;
      justify-content: center;
      max-width: none;
      text-align: center;
      column-gap: var(--mg-space-12);
    }

    &__info {
      max-width: 588px;

      @media (max-width: 575.98px) {
        width: 100%;
        margin-bottom: var(--mg-space-8);
      }

      &__message {
        font-size: 13px;
        line-height: 18px;
      }

      &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      &__message, &__title {
        white-space: pre-wrap;

        p {
          margin: 0;
        }

        ol, ul {
          margin-bottom: 0;
        }

        @media (max-width: 767.98px) {
          font-size: 12px;
        }
      }
    }

    &__timer-block {
      display: flex;
      align-items: center;
      border-color: var(--mg-color-primary-50);

      &__dots {
        font-size: 14px;
        font-weight: 600;
        margin: 0 var(--mg-space-4);
      }

      &__elem {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 28px;
        padding: var(--mg-space-4);
        border: 1px solid;
        border-radius: var(--mg-radius-4);
      }

      &__text {
        white-space: nowrap;

        &__dot {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: var(--mg-space-8);
          margin-bottom: 1px;
          border-radius: 50%;
        }
      }
    }
  }

  &__btn {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    padding: var(--mg-space-4) var(--mg-space-8);
    white-space: nowrap;
    text-decoration: none;
    border-radius: var(--mg-space-4);

    @media (max-width: 767.98px) {
      font-size: 12px;
      width: auto;
    }

    &.default {
      color: var(--mg-color-accent-30);
      background-color: var(--mg-color-light);

      &:active {
        color: var(--mg-color-accent-30);
      }
    }
  }
}
